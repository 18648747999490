import { Log, UserManager, WebStorageStateStore } from '@twigeducation/oidc-client-react';

/**
 * NOTE: Temporary fix for the issue where access token and id token are the same
 * and we got into a problem with the refreshing of tokens. This should last around ~8
 * days. After that, we should remove this code and address the root cause of the issue in Auth
 * properly.
 */
try {
    const storageKey = `oidc.user:${window.config.OIDC_PROVIDER_URL}/:${window.config.SSO_CLIENT_ID}`;
    const oidcUser = JSON.parse(localStorage.getItem(storageKey));

    if (oidcUser && oidcUser.access_token === oidcUser.id_token) {
        Object.keys(localStorage)
            .filter(key => key.startsWith('oidc'))
            .forEach(key => localStorage.removeItem(key));
    }
} catch (error) {
    console.log({ userManagerError: error });
}

const userManager = new UserManager({
    authority: `${window.config.OIDC_PROVIDER_URL}/`,
    automaticSilentRenew: true,
    client_id: window.config.SSO_CLIENT_ID,
    post_logout_redirect_uri: window.config.SSO_LOGOUT_CALLBACK_URL,
    monitorSession: window.config.SSO_MONITOR_SESSION,
    redirect_uri: window.config.SSO_LOGIN_CALLBACK_URL,
    response_type: 'code',
    scope: 'openid read write',
    userStore: new WebStorageStateStore({ store: window.localStorage }),
});

export const configureOIDCLogging = environment => {
    Log.logger = console;
    if (environment.toLowerCase() === 'production') {
        Log.level = Log.ERROR;
    } else {
        Log.level = Log.DEBUG;
    }
};
configureOIDCLogging(NODE_ENV.toLowerCase());

export default userManager;
